import React, {useState, useEffect} from 'react';
import './App.css';
import axios from 'axios';
import {Auth, Hub} from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/core/Alert';
import { makeStyles } from '@material-ui/styles';
import theme from './theme'
import { spacing } from '@material-ui/system';
import { sha256, sha224 } from 'js-sha256';

function Copyright() {
  
  return (
    <Typography variant="body2" color="textSecondary" align="center" mt={2}>
      {' © '}
      <Link color="inherit" href="https://mywavecard.co.uk/">
        Wave
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      All rights reserved.
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert:{
    margin: theme.spacing(2, 0, 2),
  }
}));

const initialFormState={
  username:'',passcode:'',email:'',vericode:'',formType:'signIn',challenge:'',error:false, errorMessage:''
}

const cognitoState={
  cognitoUser:null
}

function App() {
  
  
  const [formState, updateFormState] = useState(initialFormState);
  const [cognitoState, updateCognitoState] = useState(null);
  useEffect(()=>{
    checkUser()
    setAuthListener()
  },[])

  async function setAuthListener() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signOut':
          updateFormState(()=>({...formState, formType: 'signIn'}))
            break;
        default:
          break;
      }
    });
  }

  function getParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
      redirect_uri: searchParams.get('redirect_uri') || '',
      scope: searchParams.get('scope') || '',
      client_id: searchParams.get('client_id') || '',
      response_type: searchParams.get('response_type') || '',
      state: searchParams.get('state') || '',
    };
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      updateCognitoState(()=>({...formState, cognitoUser: user}))
      updateFormState(()=>({...formState, formType: 'signedIn'}))
    } catch (error) {
 
    }
  }

  function onChange(e){
      e.persist()
      updateFormState(()=>({...formState, [e.target.name]: e.target.value, error: false, errorMessage:''}))
  }

  const {formType} = formState

  async function verify() {
    const {cognitoUser} = cognitoState
    const {vericode} = formState
    let challengeResponse = vericode;

    
    // to send the answer of the custom challenge
    Auth.sendCustomChallengeAnswer(cognitoUser, challengeResponse, {"source": "open-banking-authorization"})
        .then(
          cognitoUser => {
            console.log('Successful verification');
            const params = getParams(window.location);
            const redirectUrl = params.redirect_uri + "?code=auth_code&state="+ params.state 
            window.location.replace(redirectUrl);
            // updateFormState(()=>({...formState, formType: 'signedIn'}))
          }
        )
        .catch(
          err => {
            console.log(err)
            updateFormState(()=>({...formState, error: true, errorMessage:'Verification failed. Try to login again.',formType: 'signIn'}))
          }
        );
  }
  async function signIn() {

    const {username, passcode} = formState
    // console.log('PASSCODE=' + passcode);
    let enc_passcode = sha256(passcode);
    // console.log('SHA256=' + enc_passcode);
    // const headers = {
    //   accept: 'text/html'
    //   // 'Authorization': 'Bearer my-token',
    //   // 'My-Custom-Header': 'foobar'
    // };
    // let queryString="response_type=code";
    // queryString +="&client_id=426p2k0bq5v89089ueeaoh2asp";
    // queryString +="&redirect_uri=http://localhost:3000";
    // queryString +="&state=STATE";
    // queryString +="&scope=aws.cognito.signin.user.admin+email+https://cmll3q0h8e.execute-api.eu-west-1.amazonaws.com/snd/accounts/read+openid+phone+profile";
    // // https://cflsnd.auth.eu-west-1.amazoncognito.com/login?client_id=426p2k0bq5v89089ueeaoh2asp&response_type=code&scope=aws.cognito.signin.user.admin+email+https://cmll3q0h8e.execute-api.eu-west-1.amazonaws.com/snd/accounts/read+openid+phone+profile&redirect_uri=http://localhost:3000
    // axios.get('https://cflsnd.auth.eu-west-1.amazoncognito.com/oauth2/authorize?' + queryString, { headers })
    //   .then(response => console.log(response))
    //   .catch(error => {
    //     // this.setState({ errorMessage: error.message });
    //     console.error('ERROR: ', error);
    //   });

        
        Auth.signIn(username, enc_passcode, {"source": "open-banking-authorization"})
        .then(user => {
            if (user.challengeName === 'CUSTOM_CHALLENGE') {
                updateCognitoState(()=>({...formState, cognitoUser: user}))
                updateFormState(()=>({...formState, formType: 'customChallenge', challenge: user.challengeParam.CUSTOM_CHALLENGE_NAME}))
            } else {
              updateFormState(()=>({...formState, formType: 'signedIn'}))
              
              const params = getParams(window.location);

              var bodyFormData = new FormData();
              bodyFormData.append('client_id', params.client_id);
              bodyFormData.append('scope', params.scope);
              bodyFormData.append('resource-owner', username);
              bodyFormData.append('redirect_uri', params.redirect_uri);
              bodyFormData.append('original-url', window.location);
              bodyFormData.append('dp-state', params.state);
              bodyFormData.append('dp-data', passcode);
              
                // const headers = {
                //   'Authorization': 'Bearer my-token',
                //   'My-Custom-Header': 'foobar'
                // };
                // let queryString="response_type=code";
                // queryString +="&client_id=3dkohmav55vejclbjpuiamjckl";
                // queryString +="&redirect_uri=http://localhost:3000";
                // queryString +="&state=STATE";
                // queryString +="&scope=openid+profile+aws.cognito.signin.user.admin";
                
                // axios.get('https://wave-snd.auth.eu-west-1.amazoncognito.com/oauth2/authorize?' + queryString, { headers })
                //   .then(response => console.log(response))
                //   .catch(error => {
                //     // this.setState({ errorMessage: error.message });
                //     console.error('ERROR: ', error);
                //   });
              //   oauth/authorize
              //   - AuthorizationToken

              //  oauth/token
              //  - AccessToken (clientId and userId)
            }
        })
        .catch(err => {
          console.log('ERROR: ', err)
          updateFormState(()=>({...formState,error: true, errorMessage: 'Login failed; Invalid user ID or password.'}))
        });
        
  } 
  const classes = useStyles();
  const {error, errorMessage} = formState

  return (
    <div className="App">
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
              <Typography
              component="h1"
              variant="h4"
              color="primary"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Wave<span className="jss9">.</span>
            </Typography>
      {
        formType === 'signIn' &&(
            <div>
            <Typography component="h1" variant="h5">
            Sign in
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                onChange={onChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passcode"
                label="Passcode"
                type="password"
                id="passcode"
                onChange={onChange}
            />
            {
              error &&
                <Alert 
                    className={classes.alert}
                    variant="filled" 
                    severity="error">
                      {errorMessage}
                </Alert>
            }
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={signIn}
            >
                Authorize
            </Button>
            </form>
         </div>
        )
      }
      {
        formType === 'customChallenge' &&(
          <div>
            <Typography component="h1" variant="h5">
            Verification
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="vericode"
                label="vericode"
                name="vericode"
                autoFocus
                onChange={onChange}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={verify}
            >
                Verify
            </Button>
            </form>
        </div>
        )
      }
      {
        formType === 'signedIn' &&(
          <div>
              Welcome {cognitoState.cognitoUser.username}
              <div>
                <button onClick={
                  ()=> Auth.signOut()
                }>Sign Out</button>
              </div>
          </div>
        )
      }
       </div>
      {/* <div><AuthorizationIframe iframeurl="https://cflsnd.auth.eu-west-1.amazoncognito.com/oauth2/authorize?client_id=426p2k0bq5v89089ueeaoh2asp&response_type=code&scope=aws.cognito.signin.user.admin+email+https://cmll3q0h8e.execute-api.eu-west-1.amazonaws.com/snd/accounts/read+openid+phone+profile&redirect_uri=http://localhost:3000" /></div> */}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
    
  );
}

export default App;
